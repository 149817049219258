<template>
  <v-dialog
    v-model="dialog"
    :persistent="loading"
    max-width="500px"
    scrollable
    :disabled="disabled"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        :color="isFab ? 'color3Text color3--text' : 'color3 color3Text--text'"
        :fab="isFab"
        :small="isFab"
        v-on="on"
        :disabled="disabled"
      >
        <v-icon v-if="isFab">fas fa-plus</v-icon>
        <span v-else>{{text ? text : 'Add Pool Play'}}</span>
      </v-btn>
    </template>
    <v-card>
      <v-toolbar color="color2 color2Text--text">
        <v-toolbar-title>Pool Play Creator</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="pt-0">
        <pool-wizard
          :open="dialog"
          :round="round"
          @cancel-click="dialog = false"
          @flights-created="dialog = false"
        ></pool-wizard>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import PoolWizard from './Index.vue'

export default {
  props: ['round', 'fab', 'text', 'disabled'],
  data () {
    return {
      dialog: false,
      loading: false
    }
  },
  computed: {
    isFab () {
      return this.fab || this.fab === ''
    }
  },
  components: {
    'pool-wizard': PoolWizard
  }
}
</script>

<style>

</style>

import PoolOptions from './PoolOptions'
import MatchSettings from './MatchSettings'
import uniq from 'lodash.uniq'
import DtoUpdate from './DtoUpdate'

export default class FlightCreator {
  id = 0
  options = null
  _selectedOption = null
  settings = []
  teams = 0
  fivb = false
  aau3 = false
  courts = null
  namingConvention = null
  powerPools = null
  props = []

  constructor (sdk, dto, tempTypes) {
    this.sdk = sdk
    this.update(dto)
    tempTypes && tempTypes.length && this.addTempTypes(tempTypes)
  }

  // methods
  update (dto) {
    if (typeof dto === 'string') dto = JSON.parse(dto)
    const exclude = []

    DtoUpdate(dto, this, exclude)
    if (this.teams > 0) {
      this.options = new PoolOptions(this.teams, this.isKob)
      this.selectedOption = this.options.suggestedOption
      this.onOptionSelected()
    }
  }

  addTempTypes (tempTypes) {
    this.options = new PoolOptions(this.teams, this.isKob, tempTypes)
  }

  onOptionSelected () {
    this.settings = uniq(this.selectedOption.pools).sort().map(m => {
      const setting = new MatchSettings({
        poolsOf: m,
        matchTypeId: m === 3 ? 2 : 1,
        startTime: this.startTime
      }, true, true)
      return setting
    })
  }

  // getters
  get dto () {
    return {
      settings: this.settings.map(setting => setting.dto),
      poolCount: this.selectedOption.id,
      fivb: this.fivb,
      aau3: this.aau3,
      courts: this.courts,
      namingConvention: this.namingConvention,
      powerPools: this.powerPools,
      props: this.props && this.props.length ? this.props : null
    }
  }

  get selectedOption () {
    return this._selectedOption
  }

  set selectedOption (val) {
    this._selectedOption = val
    this.onOptionSelected()
  }

  get isKob () {
    return this.props.includes('kob')
  }
}

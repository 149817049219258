<template>
  <v-container v-if="flight && flight.teams.length > 0">
    <v-row dense>
      <v-col cols="12" class="headline text-center">
        This division has no teams
      </v-col>
    </v-row>
    <v-row dense justify="center">
      <v-btn color="color3 color3Text--text" @click.stop="dialog = false">Ok</v-btn>
    </v-row>
  </v-container>
  <v-container fluid class="pt-0" v-else-if="flight">
    <v-row dense>
      <v-col class="text-right" cols="12" >
        <v-btn color="color3" text class="xsmall ma-0 py-0" @click.stop="advanced = !advanced">{{advanced ? 'Simple' : 'advanced'}}</v-btn>
      </v-col>
    </v-row>
    <!-- Flights -->
      <v-row dense align="center">
        <v-col cols="12" sm="8">
          <span class="title">Pools: </span>
          <span class="subheading">{{flight.teams}} teams</span>
        </v-col>
        <v-col cols="12">
          <v-autocomplete
            label="Number Of Pools"
            v-model="flight.selectedOption"
            :items="flight.options.options"
            item-text="description"
            item-value="id"
            return-object
            attach
            color="color3"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-expand-transition>
        <v-row dense v-if="advanced || division.isKob || division.isDuals">
          <!-- KOB -->
          <v-col cols="12">
            <v-checkbox
              label="KOB/QOB"
              v-model="flight.props"
              value="kob"
              color="color3"
              class="mt-0 shrink"
              hide-details
              :disabled="division.isKob"
            ></v-checkbox>
            <v-checkbox
              label="Duals"
              v-model="flight.props"
              :value="division.dualProp"
              color="color3"
              class="mt-0 shrink"
              hide-details
            ></v-checkbox>
          </v-col>
          <v-col cols="12" v-if="flight.isKob != division.isKob || division.isKob">
            <setting-toggle
              propName="kob"
              label="All rounds in this division will be KOB/QOB"
              :isDivisionProp="true"
              :propList="true"
              :dataId="division.id"
              iClass="mt-0"
            ></setting-toggle>
          </v-col>
        </v-row>
      </v-expand-transition>
      <!-- COURTS -->
        <v-expand-transition>
          <v-row dense v-if="!division.isDuals">
            <v-col cols="12">
              <v-text-field
                label="Courts"
                v-model="flight.courts"
                hint="Enter the court numbers/names seperated by a comma i.e. 1,2,3"
                persistent-hint
                color="color3"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-expand-transition>
      <!-- advanced -->
        <v-expand-transition>
          <div v-if="advanced">
            <v-row dense>
              <!-- NAMING -->
              <v-col cols="12">
                <v-text-field
                  label="Naming Convention"
                  v-model="flight.namingConvention"
                  hint="Enter the naming convention for pools. Use {A}, {#}, or {count} as placeholders for pool letter, pool number and pool count."
                  persistent-hint
                  color="color3"
                ></v-text-field>
              </v-col>
              <!-- POWER POOLS -->
              <v-col cols="12">
                <v-autocomplete
                  label="Number Of Power Pools"
                  v-model="flight.powerPools"
                  :items="powerPools"
                  attach
                  color="color3"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </div>
        </v-expand-transition>
      <!-- Match Format -->
        <v-row dense>
          <v-col cols="12" class="title">
            Match Format
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" v-for="(setting) in flight.settings" :key="setting.poolsOf">
            <v-card>
              <v-toolbar dense color="color2 color2Text--text">
                <v-toolbar-title>Pools of {{setting.poolsOf}}</v-toolbar-title>
              </v-toolbar>
                <loading-bar :value="loading"></loading-bar>
                <v-card-text v-if="getTemplates(setting).length" class="py-0">
                  <v-select
                    :items="getTemplates(setting)"
                    item-text="name"
                    item-value="id"
                    v-model="setting.organizationTemplateId"
                    label="Custom Template"
                    :disabled="flight.fivb"
                    color="color3"
                    item-color="color3"
                  ></v-select>
                </v-card-text>
              <match-settings :setting="setting" :edit="true"></match-settings>
              <v-card-text class="py-0" v-if="setting.poolsOf === 4">
                <v-checkbox
                  label="Modified Olympic Pools"
                  v-model="flight.fivb"
                  v-if="setting.poolsOf === 4"
                  color="color3"
                ></v-checkbox>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

    <v-row dense justify="center" class="pt-2">
      <v-btn
        color="color3 color3Text--text"
        class="mr-2"
        @click.native="onCreateClick"
        :disabled="false"
      >Create</v-btn>
      <v-btn
        @click.native="create = false; $emit('cancel-click')"
      >Cancel</v-btn>
    </v-row>
  </v-container>
</template>

<script>
import FlightCreator from '@/classes/FlightCreator'
import * as mutations from '@/store/MutationTypes'
import MatchSettings from '@/components/Tournament/RoundSettings/MatchSettings.vue'
import SettingToggle from '@/components/Tournament/QuickSettings/SettingToggle'
import { mapGetters } from 'vuex'

export default {
  props: ['round', 'open'],
  data () {
    return {
      window: 0,
      create: false,
      advanced: false,
      flight: null,
      startTime: null,
      assignTimes: true,
      SEED: 0,
      FLIGHT: 1,
      loading: false,
      templates: []
    }
  },
  computed: {
    ...mapGetters(['tournament', 'getDivision']),
    username () {
      return this.tournament && this.tournament.organization.username
    },
    powerPools () {
      return this.flight && this.flight.selectedOption ? [...Array(this.flight.selectedOption.id + 1).keys()] : []
    },
    division () {
      return this.$route.params.divisionId && this.getDivision(+this.$route.params.divisionId)
    },
    dKob () {
      return this.division.isKob
    },
    fKob () {
      return this.flight.isKob
    },
    tempTypes () {
      return this.templates.filter(f => f.type === 'Pool').map(m => m.template.poolsOf)
    }
  },
  methods: {
    getTemplates (setting) {
      const n = setting.poolsOf
      const a = this.templates.filter(f => f.type === 'Pool' && f.template.poolsOf === n)
      if (a.length) {
        if (this.flight.isKob) {
          if (n > 6) setting.organizationTemplateId = a[0].id
        } else {
          if (n > 7) setting.organizationTemplateId = a[0].id
        }
        a.unshift({ name: 'None', id: null })
      }
      return a
    },
    onCreateClick () {
      this.$store.commit(mutations.LOADING, 'PoolWizard')
      const dto = this.flight.dto
      console.log(dto)
      this.$VBL.post.flights([dto], this.round.id)
        .then(() => {
          this.$emit('flights-created')
          this.round.flightsCreated = true
          this.$router.push({ name: 'pools-home', params: { dayId: this.round.id } })
        })
        .catch(err => {
          console.log(err.response)
        })
        .finally(() => {
          this.$store.commit(mutations.LOADING_COMPLETE, 'PoolWizard')
        })
    },
    addFlight () {
      this.flight = new FlightCreator(this.$VBL, {
        teams: this.round.teamCount || this.round.teams.filter(team => !team.isDeleted).length,
        startTime: this.round.dtStart
      }, this.tempTypes)
      if (this.division.isKob) this.flight.props.push('kob')
      if (this.division.isDuals) this.flight.props.push(this.division.dualProp)
    },
    init () {
      this.loadTemplates()
      this.startTime = this.round.dtStart
      this.addFlight()
    },
    loadTemplates () {
      this.loading = true
      this.$VBL.organization.templates.get(this.username, 'pool')
        .then(r => {
          this.templates = r.data
          this.setDefaults()
        })
        .catch(e => console.log(e.response))
        .finally(() => {
          this.loading = false
        })
    },
    setDefaults () {
      if (this.templates && this.templates.length && this.flight && this.flight.settings) {
        this.flight.settings.forEach(s => {
          const n = s.poolsOf
          const a = this.templates.find(f => f.default && f.type === 'Pool' && f.template.poolsOf === n)
          if (a) {
            s.organizationTemplateId = a.id
            const ms = a.matchSetting
            if (ms) {
              ms.matchTypeId && s.setPlayTypeById(ms.matchTypeId)
              if (ms.minutesPerMatch) s.minutesPerMatch = ms.minutesPerMatch
              if (ms.gameSettings) s.gameSettings = ms.gameSettings
            }
          }
        })
      }
    }
  },
  watch: {
    'flight.fivb': function (v) {
      if (v) {
        this.flight.settings.filter(f => f.poolsOf === 4).forEach(s => { s.organizationTemplateId = null })
      }
    },
    'flight.isKob': function (v) {
      this.flight.update({})
    },
    'flight.selectedOption': 'setDefaults',
    open: function (val) {
      if (val) {
        this.init()
      }
    },
    tempTypes: function () {
      this.flight.addTempTypes(this.tempTypes)
    }
  },
  mounted () {
    this.init()
  },
  components: {
    MatchSettings,
    SettingToggle
  }
}
</script>

<style>

</style>
